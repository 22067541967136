import _parse from "./parse";
import _walk from "./walk";
import _stringify from "./stringify";
import _unit from "./unit";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var parse = _parse;
var walk = _walk;
var stringify = _stringify;

function ValueParser(value) {
  if ((this || _global) instanceof ValueParser) {
    (this || _global).nodes = parse(value);
    return this || _global;
  }

  return new ValueParser(value);
}

ValueParser.prototype.toString = function () {
  return Array.isArray((this || _global).nodes) ? stringify((this || _global).nodes) : "";
};

ValueParser.prototype.walk = function (cb, bubble) {
  walk((this || _global).nodes, cb, bubble);
  return this || _global;
};

ValueParser.unit = _unit;
ValueParser.walk = walk;
ValueParser.stringify = stringify;
exports = ValueParser;
export default exports;